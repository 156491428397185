import { bindCallback, Observable } from 'rxjs';
import $ from "jquery";

export interface UserI {
    uid: string;    
    displayName: string ;
    expiredAds: number;
 }
  
export class User {
    private static instance: User;
    private _uid: string = null;    
    private _displayName: string = null;
    private _expiredAds: number = 0;

    private _expired$: Observable<UserI>;

    private constructor() {}

    public static getInstance(): User {
        if (!User.instance) {
            User.instance = new User();
        }

        return User.instance;
    }


    get uid(): string {
        return this._uid;
    }
    set uid(value: string) {
        this._uid = value;
        console.log('calling uid')
    }

    get displayName(): string {
        return this._displayName;
    }
    set displayName(value: string) {
        this._displayName = value;
    }  
    
    get expired$(): Observable<UserI> {
        return this._expired$;
    }

    get expiredAds(): number {
        return this._expiredAds;
    }
    set expiredAds(value: number) {
        this._expiredAds = value;
    }  
    
    getUser():Observable<any> {
        const observable = bindCallback($.getJSON);
        const result = observable( `https://us-central1-crcasas2018.cloudfunctions.net/webapi/api/v1/users/${this.uid}`);
        return result;
        //result.subscribe(x => console.log(x), e => console.error(e));

        //return new Observable( subscriber => {
        //    $.getJSON( `https://us-central1-crcasas2018.cloudfunctions.net/webapi/api/v1/users/${this.uid}`, function( data:any ) {
        //        let user: UserI = { uid: data.uid, displayName: data.displayName, expiredAds: data.expiredAds }
        //        console.log(user);
        //        subscriber.next(user)
        //    });  
        //}) 
                  
    }     

    
}
