import './styles/style.scss';
import './styles/custom.css';

import $ from "jquery";
import * as Bootstrap from 'bootstrap'
import { MyFirebase } from './app/main/firebase';

import 'lightgallery'
import 'lg-thumbnail'
import 'lg-fullscreen'
import '@popperjs/core'

import { View } from './app/main/view';


const myFirebase = MyFirebase.getInstance();
const view = new View()

const testMessage: string = 'Welcome to crCasas';
console.log(Bootstrap.Tooltip.VERSION);

type Images = {
    id: string;
    small: string;
    medium: string;
    big: string;
};

const searchParams = new URLSearchParams(window.location.search)  

let uid:string = null;
if (searchParams.has('uid') ) {
  uid = searchParams.get('uid')
} else uid = null // 'f2duP05EjmM9TYIVEfb4TFogXUj1'


