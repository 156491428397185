// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import $ from "jquery";
import firebase from "firebase/app";
// Add the Firebase services that you want to use
import "firebase/auth";
import "firebase/firestore";
const firebaseui = require('firebaseui');

import { Subject, BehaviorSubject, bindCallback, Observable } from 'rxjs';


import { Service } from './service'
import { Utils } from './utils'


import { User, UserI } from '../models/user'


const firebaseConfig = {
    apiKey: "AIzaSyBtjuJGM30UW_k5YG6MPzsUoriwy81DTW8",
    authDomain: "crcasas2018.firebaseapp.com",
    databaseURL: "https://crcasas2018.firebaseio.com",
    projectId: "crcasas2018",
    storageBucket: "crcasas2018.appspot.com",
    messagingSenderId: "385260406538",
    appId: "1:385260406538:web:436e0abf440a052bef5cf3",
    measurementId: "G-G433YKJ2LE"   
  };
  
  // Initialize Firebase

// basarse en esto
// https://github.com/firebase/firebaseui-web/blob/master/demo/public/app.js



  export class MyFirebase {
    private static instance: MyFirebase;

    service: any;
    utils: any;
    user:User = User.getInstance();

    userUid$ = new Subject<string>();
    displayName$ = new BehaviorSubject<string>('');    
    expiredAds$ = new BehaviorSubject<number>(0);
    signedIn$ = new BehaviorSubject<boolean>(false); // 0 is the initial value
    loadingUI$ = new BehaviorSubject<boolean>(false);


    constructor() {
      let self = this;
      this.service = Service.getInstance();
      this.utils = Utils.getInstance();

      this.userUid$.subscribe({
        next: (_uid) => {
          console.log(_uid);         
          if (_uid)  $.getJSON( `https://us-central1-crcasas2018.cloudfunctions.net/webapi/api/v1/users/${_uid}/properties/expiredcount`, function( data:any ) {
                  console.log(data)
                  self.expiredAds$.next( data );
          });           
        }
      });

     


      firebase.initializeApp(firebaseConfig);

      var ui = new firebaseui.auth.AuthUI(firebase.auth());
      var uiConfig = {
        callbacks: {
          signInSuccessWithAuthResult: function(authResult:any, redirectUrl:any) {
            // User successfully signed in.
            // Return type determines whether we continue the redirect automatically
            // or whether we leave that to developer to handle.

            // $('#user-signed-in').removeClass('d-none');   


            self.onSignedIn(authResult);
            return true;

          },
          uiShown: function() {

            self.loadingUI$.next( false );
            
          }
        },
        // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
        signInFlow: 'popup',
        signInSuccessUrl: '#',
        signInOptions: [
          // Leave the lines as is for the providers you want to offer your users.
          firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          firebase.auth.FacebookAuthProvider.PROVIDER_ID,
          firebase.auth.EmailAuthProvider.PROVIDER_ID
        ],
        // Terms of service url.
        tosUrl: '<your-tos-url>',
        // Privacy policy url.
        privacyPolicyUrl: '<your-privacy-policy-url>'
      };    

      ui.start('#firebaseui-auth-container', uiConfig);


      firebase.auth().onAuthStateChanged(function(user) {
        if (user) {

          self.displayName$.next( user.displayName);
          self.userUid$.next( user.uid);
          self.signedIn$.next( true );

        } else {
          self.signedIn$.next( false );
          self.loadingUI$.next( true );
          ui.start('#firebaseui-auth-container', uiConfig);
          self.userUid$.next( null);          

        }
      });

      this.initApp();
      
    }

    public static getInstance(): MyFirebase {
      if (!MyFirebase.instance) {
        MyFirebase.instance = new MyFirebase();
      }

      return MyFirebase.instance;
    }

    getUser(uid:string):Observable<any> {
      const observable = bindCallback($.getJSON);
      const result = observable( `https://us-central1-crcasas2018.cloudfunctions.net/webapi/api/v1/users/${uid}`)
      return result;
    }

    private onSignedIn(authResult:any) {
      console.log(authResult)
      this.userUid$.next( authResult.uid);
      this.signedIn$.next( true );
    }

    private onSignedOut() {
      
    }


/**
 * Initializes the app.
 */
initApp() {
  

}

signOut() {
  firebase.auth().signOut(); 
}

pauseAd(uid:string, forSale: boolean, forRent:boolean) {
  const today = new Date()
  console.log(forSale);
  firebase.firestore().collection('tasks/expire/ad').add({
                                                            uid: uid, 
                                                            forSale: forSale, 
                                                            forRent: forRent, 
                                                            dateTsCreated: today, 
                                                            action:'P',
                                                            pausedBy: 'U'
                                                          }).then( (u) => console.log(u))

  //firebase.firestore().collection('users').doc('BQH6MOifAdZ0DgbmfM9aZOfwNBk1').get().then(
  //  (u) => console.log(u.data())
  //)  

}

renewAd(uid:string, forSale: boolean, forRent:boolean) {
  const today = new Date()
  firebase.firestore().collection('tasks/expire/ad').add(
                            { uid: uid, 
                              forSale: forSale, 
                              forRent: forRent, 
                              dateTsCreated: today, 
                              action:'R'
                            }
                          ).then(
    (u) => console.log(u)
  )

}

  getProperty(uid:string):any {
    const promise = $.getJSON(`https://us-central1-crcasas2018.cloudfunctions.net/webapi/api/v1/properties/${uid}`);
    return promise;
  }

getProperties(uid: string):any {
 
  const promise =  $.getJSON( `https://us-central1-crcasas2018.cloudfunctions.net/webapi/api/v1/users/${uid}/properties/expired` ) 
    return promise;
  }  
}  