import $ from "jquery";

export class Utils {
    private static instance: Utils;

    private constructor() {}

    public static getInstance(): Utils {
        if (!Utils.instance) {
            Utils.instance = new Utils();
        }

        return Utils.instance;
    }

    public hideEl(el:string) {
        $( el ).addClass( "d-none" );   
      }
    
      public showEl(el:string) {
        $( el ).removeClass( "d-none" ); 
    }
    
}
