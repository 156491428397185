import $ from "jquery";
import { fromEvent } from 'rxjs';
import * as Bootstrap from 'bootstrap'
import Masonry from 'masonry-layout'
import ImagesLoaded from 'imagesloaded'

import { MyFirebase } from './firebase'
import { Utils } from './utils';

const signedInClass: string = '.on-signed-in'
const signedOutClass: string = '.on-signed-out'
const userNameClass: string = '.user-name'
const expiredAdsClass: string = '.expired-count'
const onExpiredClass: string = '.on-expired'
const onNoExpiredClass: string = '.on-no-expired'
const uiLoaderClass: string = '.fb-loader'
const noMoreClass: string = '.no-more-items'

const signOutBtn: string = '#sign-out'
const loadMoreBtn: string = '#load-more-items'

import { BehaviorSubject } from 'rxjs';

// arbol de eventos, hacerlo sencillo
// A. Sin loger
// B. Logeado

// B.1 Logeado con anuncios
// b.2 Logeado SIN anuncios

// Del momento en que se loguea, hasta que este completo
// hay que mostrar Loading properties y bienvido Oscar

export class View {

    fb: any;
    utils:any;
    tstPause: any;
    tstRenew: any;
    lastVisible: any;
    msnry: any;
    uid: any;
    expiredAds: number = 0;

    loadingItems$ = new BehaviorSubject<boolean>(false);
    moreItems$ = new BehaviorSubject<boolean>(true);

    constructor() {
        let self = this;
        this.fb = MyFirebase.getInstance();
        this.utils = Utils.getInstance();


        this.tstPause = new Bootstrap.Toast(document.getElementById("toasts-pause"), {})
        this.tstRenew = new Bootstrap.Toast(document.getElementById("toasts-renew"), {})


        this.fb.signedIn$.subscribe(
            (_signedIn:boolean) => {
                if (_signedIn) {
                    self.utils.showEl( signedInClass );         	
                    self.utils.hideEl( signedOutClass );    
                } else {
                    self.utils.hideEl( signedInClass );         	
                    self.utils.showEl( signedOutClass );  
                }
            }
        ) 

        this.fb.userUid$.subscribe(
            (_userUid:string) => {
                if (_userUid)  {
                  this.getProperties(_userUid);
                  //this.getProperties("zWiiF91tQhSIeLSfu6vj2Bj0rhf2");
                }
            }
        )         

        this.fb.loadingUI$.subscribe(
            (_loadingUI:boolean) => {
                if (_loadingUI) {
                    self.utils.showEl( uiLoaderClass );         	    
                } else {
                    self.utils.hideEl( uiLoaderClass );         	
                }
            }
        )         
        
        this.moreItems$.subscribe(
        
          (_moreItems:boolean) => {
              self.moreItems(_moreItems)
          }
        )         
      
        this.loadingItems$.subscribe(
        
          (_loadingItems:boolean) => {
              self.loadingItems(_loadingItems)
          }
        )  


        this.fb.displayName$.subscribe(
            (_displayName:string) => {
                $(userNameClass).html( _displayName );
            }
        )  
        
        this.fb.expiredAds$.subscribe(
            (_expiredAds:number) => {
                $(expiredAdsClass).html( _expiredAds.toString() + ' ');
                self.expiredAds = _expiredAds;
            }
         );         

        $(signOutBtn).on( "click", function() {
            console.log('signed out')
            self.fb.signOut();    
        });  

        $(loadMoreBtn).on( "click", function() {
          self.getPropertiesNext(self.uid)   
      });  

    }


    getProperties(uid: string) {
        const self = this;
        const utils = Utils.getInstance();
        this.uid = uid;
      
        $('#album-container').empty();
        utils.showEl("#loader");
        utils.hideEl(".on-expired") 
        utils.hideEl(".on-no-expired")

        self.loadingItems$.next( true );
      
        $.getJSON( `https://us-central1-crcasas2018.cloudfunctions.net/webapi/api/v1/users/${uid}/properties/expired`, function( data:any[] ) {
       
          self.loadingItems$.next( false );

          if (data.length<15) self.moreItems$.next(false)
          else self.moreItems$.next(true) 

          if (data.length>0) { 
            utils.hideEl(".on-no-expired") 
            utils.showEl(".on-expired") 
          } else {
              utils.hideEl(".on-expired") 
              utils.showEl(".on-no-expired")         
          }; 
      
          for (let entry of data) {
            self.addProperty(entry);
          }

          self.lastVisible = data[data.length-1];
      
          const imgld =  ImagesLoaded('#album-container', function() {
                self.msnry = new Masonry( '#album-container', {
                  itemSelector: '.grid-item',
                  percentPosition: true
                });
                utils.hideEl("#loader");
          })
      
      
        }); 
      
      }    


      getPropertiesNext(uid: string) {
          const self = this;
          let item:string = '';

          self.loadingItems$.next( true );
          $.post( `https://us-central1-crcasas2018.cloudfunctions.net/webapi/api/v1/users/${uid}/properties/expired`, self.lastVisible)
          .done(function( data ) {
            self.loadingItems$.next( false );
              if (data.length<15) self.moreItems$.next(false)
              else self.moreItems$.next(true) 

              for (let entry of data) {
                self.addProperty(entry);
              }              

              const imgld =  ImagesLoaded('#album-container', function() {
                self.msnry.reloadItems()
                self.msnry.layout()

              })                         
          });
      
      } 

       addProperty(data:any) {
        // const temp = document.getElementsByTagName("template")[0];
        // const clon = temp.content.cloneNode(true);
        // document.getElementById("album-container").appendChild(clon);
        const template = $('#tmpl0').html();

        $("#album-container").append(template);   
        const child:any = document.getElementById("album-container").lastChild;
        child.id = `UID${data.uid}`;
        child.querySelector(".card-title").innerHTML = data.title;
        child.querySelector(".card-subtitle").innerHTML = data.priceCurrency + ' ' + this.getPrice(data);    
        child.querySelector(".desc").innerHTML = data.desc;
        child.querySelector("img").src =  data.gallery[0].medium
        child.querySelector(".card-header").innerHTML =  this.daysSince(data.lastUpdate) + ' días de antiguedad' 
        child.querySelector(".sale").innerHTML =  this.isForSale(data) 
        child.querySelector(".rent").innerHTML =  this.isForRent(data) 
        child.querySelector("a").href =  `https://crcasas.com/account/my-properties/${data.uid}`
      
        child.querySelector(".btn-pause").id = `UIDPA${data.uid}`;
        child.querySelector(".btn-renew").id = `UIDRE${data.uid}`;
      
        fromEvent(child.querySelector(".btn-pause"), 'click').subscribe((b:any) => {
            const _uid = b.target.id.slice(5);
            const _uidRenew = `UIDRE${_uid}`;
            const _uidAd = `UID${_uid}`;
            let forSale = true;
            let forRent = true;
            const forSaleLabel = (<HTMLInputElement>document.getElementById(_uidAd )).querySelector(".sale").innerHTML;
            const forRentLabel = (<HTMLInputElement>document.getElementById(_uidAd )).querySelector(".rent").innerHTML;

            if (forSaleLabel !== 'Venta') forSale = false;
            if (forSaleLabel !== 'Alquiler') forRent = false;


            (<HTMLInputElement> document.getElementById(b.target.id)).disabled = true;
            (<HTMLInputElement> document.getElementById(_uidRenew)).disabled = true;  


            this.fb.pauseAd(_uid, forSale, forRent)
            this.tstPause.show();
            
        });

        fromEvent(child.querySelector(".btn-renew"), 'click').subscribe((b:any) => {
            const _uid = b.target.id.slice(5);
            const _uidPause = `UIDPA${_uid}`;
            const _uidAd = `UID${_uid}`;
            let forSale = true;
            let forRent = true;
            const forSaleLabel = (<HTMLInputElement>document.getElementById(_uidAd )).querySelector(".sale").innerHTML;
            const forRentLabel = (<HTMLInputElement>document.getElementById(_uidAd )).querySelector(".rent").innerHTML;

            if (forSaleLabel !== 'Venta') forSale = false;
            if (forSaleLabel !== 'Alquiler') forRent = false;

            (<HTMLInputElement> document.getElementById(b.target.id)).disabled = true;
            (<HTMLInputElement> document.getElementById(_uidPause)).disabled = true;            
            this.fb.renewAd(_uid, forSale,  forRent)
            this.tstRenew.show();

        });        
      

      }    

      getPrice(ad:any):string {
        let result:string = '';
        if (ad.priceCurrency=='USD') {
          if (ad.propertyStatus[0] == 'Venta') result = ad.priceDollar.sale.toLocaleString('en')
          else result = ad.priceDollar.rent.toLocaleString('en')
        } else {
          if (ad.propertyStatus[0] == 'Venta') result = ad.priceEuro.sale.toLocaleString('en')
          else result = ad.priceEuro.rent.toLocaleString('en')
        }
        return result
      }
      
       daysSince(lastUpdate: string):number {
        const today = new Date();
        const date = new Date(lastUpdate)
        var timeinmilisec = date.getTime() - today.getTime();
        return -1* Math.round(timeinmilisec / (1000 * 60 * 60 * 24))
      }
      
       isForSale(ad:any):string {
        let result:string = '';
        if (ad.propertyStatus[0] == 'Venta') result = 'Venta'  
        return result
      }
      
      isForRent(ad:any):string {
        let result:string = '';
        if (ad.propertyStatus[1] == 'Alquiler') result = 'Alquiler'  
        return result
      }
      
       getSaleType(ad:any):string {
        let result:string = '';
        if (this.isForSale(ad) == 'Venta') result = 'vendida'
        if (this.isForRent(ad) == 'Alquiler') result = 'alquilada'
      
        return result
      }


      // EVENTS
      moreItems(more:boolean) {
        if (more) this.utils.showEl(loadMoreBtn);
        else { 
          this.utils.hideEl(loadMoreBtn);
          if (this.expiredAds > 0 ) this.utils.showEl(noMoreClass);
        }
      }

      loadingItems(loading:boolean) {
        if (loading) this.utils.hideEl(loadMoreBtn)
        else {
                if (this.expiredAds < 1 ) {
                    this.utils.showEl( onNoExpiredClass );         	
                } else this.utils.hideEl( onNoExpiredClass ); 
        }

      }      

} 