/**
 * The Singleton class defines the `getInstance` method that lets clients access
 * the unique singleton instance.
 */

const searchParams = new URLSearchParams(window.location.search);  

export class Service {
    private static instance: Service;
    uid: string = 'f2duP05EjmM9TYIVEfb4TFogXUj1';

    /**
     * The Singleton's constructor should always be private to prevent direct
     * construction calls with the `new` operator.
     */
    private constructor() { 
        if (searchParams.has('uid') ) {
          this.uid = searchParams.get('uid')
        } 
             
    }

    /**
     * The static method that controls the access to the singleton instance.
     *
     * This implementation let you subclass the Singleton class while keeping
     * just one instance of each subclass around.
     */
    public static getInstance(): Service {
        if (!Service.instance) {
            Service.instance = new Service();
        }

        return Service.instance;
    }

    /**
     * Finally, any singleton should define some business logic, which can be
     * executed on its instance.
     */
    public someBusinessLogic() {
        // ...
    }
}

/**
 * The client code.
 */
function clientCode() {
    const s1 = Service.getInstance();
    const s2 = Service.getInstance();

    if (s1 === s2) {
        console.log('Singleton works, both variables contain the same instance.');
    } else {
        console.log('Singleton failed, variables contain different instances.');
    }
}
